header {
     position: relative;
}

#index{
     min-height: 100vh;
     background: no-repeat url('../../images/img08.jpg');
     background-size: cover;
}

#church_life{
     background: no-repeat url('../../images/img07.jpg');
}

#stages {
     background: no-repeat url('../../images/img06.jpg');
}

#bylaw {
     background: no-repeat url('../../images/img010.jpg');
}

#assessment {
     background: no-repeat url('../../images/img011.jpg');
}

#grant_program {
     background: no-repeat url('../../images/img05.jpg');
}

#contacts {
     background: no-repeat url('../../images/img04.jpg');
}

#church_life,
#stages,
#assessment,
#bylaw,
#contacts,
#grant_program {
     min-height: 60vh !important;
     background-position: center center;
     background-size: cover;
}

.hero {
     width: 100%;
     position: relative;
     display: flex;
     background-color: $color-blend !important;
     background-size: cover !important;
     background-position: center center !important;
     background-blend-mode: darken;

     @include resp(screenW768){
          flex-flow: column;
          background-position: 15% 0;
     }

     &__title {
          // width: 66.66%;
          // align-self: left;
          position: absolute;
          top: 80%;
          left: 60%;


          @include resp(screenW1024){
               position: relative;
               width: 66.66%;
               align-self: left;
               width: 100%;
          }

          @include resp(screenW768){
               position: absolute;
               top: 50%;
               left: 0;
          }

          &-text {
               text-align: center;
               text-transform: capitalize;
               font-size: $xxl;
               font-weight: 800;
               color: $color-white;
               @include resp(screenW768){
                    padding: 5rem 0;
                    font-size: $xxl;
               }
          }
     }

     &__info {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 50%;
          min-height: 10vh;
          height: 10rem;
          display: flex;
          flex-flow: row;
          justify-content: space-evenly;
          background-color: $color-white;

          @include resp(screenW1024){
               width: 100%;
               top: 0;
          }

          @include resp(screenW768){
               height: auto;
               flex-flow: column;
               justify-content: flex-start;
               padding: 0 3rem;
               background-color: transparent;
          }

          &-col {
               width: calc(100% / 4);
               display: flex;
               flex-flow: column;
               padding: 2rem 0;

               @include resp(screenW768){
                    width: 100%;
               }
          }

          &-col:first-of-type {
               @include resp(screenW1600){
                    width: 12.5%;
               }
          }

          &-row {
               display: flex;
          }

          &-title {
               padding-bottom: 1rem;
               font-size: $md;
               font-weight: 600;

               @include resp(screenW768){
                    color: $color-white;
               }
          }

          &-text{
               font-size: $md;
               
               @include resp(screenW768){
                    color: $color-white;
                    line-height: 1.5;
               }
          }

          &-link {
               padding: .5rem;

               @include resp(screenW768){
                    margin-right: 2rem;
               }
               
          }

          &-button {
               @include button(80%, 1rem, $md, $color-white, $color-link);
               @include resp(screenW768) {
                    width: 13rem;
               }

          }

          &-icon--f {
               font-size: $xl;
               color: $color-fb;
               @include resp(screenW768){
                    color: $color-white;
               }
          }

          &-icon--t {
               font-size: $xl;
               color: $color-tw;
               @include resp(screenW768){
                    color: $color-white;
               }
          }

          &-icon--l {
               font-size: $xl;
               color: $color-li;
               @include resp(screenW768){
                    color: $color-white;
               }
          }
     }
}
