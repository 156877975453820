.stages {
     height: auto;
     margin: 10rem 0;
     display: grid;
     grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
     justify-content: center;
     justify-items: center;
     grid-gap: 5rem;

     @include resp(screenW1024) {
          grid-gap: 5rem;
     }
     
}

.singleNews {
     height: 30vh;
}