.post {
     width: 100%;
     height: auto;
     margin: 5rem 0;

     &__article {
          height: auto;
          display: grid;
          
          &-col {
               width: 100%;
               height: auto;
               display: grid;
          }
     
          &-title {
               margin: 2rem 0;
               font-size: $xxl;
          }
     
          &-text {
               padding: 2rem;
               text-indent: 5rem;
               text-align: justify;
               font-size: $lg;
               line-height: 1.5;
               
          }
     
          &-time {
               padding: 3rem;
               display: block;
               text-align: right;
               font-size: $md;
          }
     }
}