@mixin button ($width, $padding, $fontSize, $color, $bgColor){
     width: $width;
     padding: $padding;
     text-align: center;
     font-size: $fontSize;
     color: $color;
     background-color: $bgColor;
     transition: all .3s linear;

     &:hover {
          transform: scale(1.1);
     }
}