// WIDTH ANIMATION 

@keyframes widthSize {
     from {
          width: 0%;
     }

     to {
          width: 100%;
     }
}

// SLIDE ANIMATION

@keyframes slideFromLeft {
     from {
          opacity: 0;
          transform: translateX(-10rem);
     }

     to {
          opacity: 1;
          transform: translate(0);
     }
}

@keyframes slideFromRight {
     from {
          opacity: 0;
          transform: translateX(100rem);
     }

     to {
          opacity: 1;
          transform: translate(0);
     }
}

@keyframes opacity {
     0% {
          opacity: 1;
     }

     50% {
          opacity: 0;
     }

     100% {
          opacity: 1;
     }
}