.contact__title {
     padding: 10rem 2rem;
     font-size: $xxl;
     font-weight: 800;
     text-align: center;
     color: $color-gray50;
}
.contact__cont {
     width: 100%;
     display: grid;
     grid-template-columns: 1fr 1fr;
     align-items: center;
     justify-content: center;

     @include resp(screenW1800){
          grid-template-columns: 1fr;
     }
}

.contact__card {
     width: 100%;
     height: 30rem;
     margin: 2rem auto;
     background-color: $color-gray5;
     border-radius: 1rem;
     box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
     transition: transform .4s linear;

     &:hover {
          transform: scale(1.05);
     }

     @include resp(screenW768) {
          height: auto;
     }

     &--bioCont {
          display: grid;
          grid-template-columns: 25rem 70%;

          @include resp(screenW768) {
               grid-template-columns: 1fr;
          }
     }

     &--dataCont {
          width: 100%;
          padding: 2rem;
          @include resp(screenW1400) {
               flex-flow: row;
          }
     }

     &--imgCont {
          width: 100%;
          height: 100%;
     }

     &--img {
          width: 25rem;
          height: 30rem;
          padding: 1rem;
          object-fit: cover;
          border-radius: 2rem;

          @include resp(screenW480){
               width: 100%;
               height: 100%;
          }
     }

     &--name {
          margin-bottom: 2rem;
          font-size: $lg;
          font-weight: 700;
     }

     &--link {
          padding-left: 1rem;
          margin-bottom: 1rem;
          display: flex;
          flex-flow: row;

          &-icon,
          &-data {
               font-size: $md;
               color: $color-gray50;
          }
     }

     &--link * + * {
          padding: 0 2rem; 
     }

     

     &--shortBio {
          font-size: $md;
          line-height: 1.5;
          text-align: justify;
     }
}