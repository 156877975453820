.stage {
     &__card{
          min-width: 25rem;
          height: auto;
          display: flex;
          flex-direction: column;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          border-radius: 1rem;
          transition: transform .4s linear;

          &:hover {
               transform: scale(1.05);
          }

          &--row {
               width: 100%;
               height: auto;
          }

          &--img {
               width: 100%;
               max-height: 80%;
               margin-bottom: 1rem;
               align-self: center;
               object-fit: cover;
               border-top-left-radius: 1rem;
               border-top-right-radius: 1rem;
          }

          &--title {
               padding: 2rem;
               font-size: $lg;
          }

          &--body {
               padding: 2rem;
               font-size: $md;
          }

          &--date {
               margin: 1rem 0;
               text-align: center;
          }
     }
}