// WEBSITE COLORS
$color-main: rgb(234,224,200);
$color-gray5: rgb(241, 241, 241);
$color-gray25: rgb(201, 201, 201);
$color-gray50: rgb(126, 126, 126);
$color-gray75: rgb(94, 94, 94);
$color-bg: rgba(24, 24, 24, 0.9);
$color-black: rgb(17, 17, 17);
$color-black-overlay: rgba(17, 17, 17, .5);
$color-white: rgba(255, 255, 255, 1);
$grid-lines-color: rgba(124, 124, 124, .1);
$color-link: rgba(255, 69, 0, 1);
$color-fb: rgb(66, 103, 178);
$color-tw: rgb(29, 161, 242);
$color-li: rgb(14, 118, 168);
$color-in: rgb(193,53,132);
$color-be: rgb(5, 62, 255);
$color-blend: rgba(0, 0, 0, .2);

// WEBSITE FONT SIZES
$xsm: 1.2rem;
$sm: 1.4rem;
$md: 1.6rem;
$lg: 2rem;
$xl: 2.5rem;
$xxl: 3.5rem;
$xxxl: 5rem;

// WEBSITE FONTS

