.intro { // ----------------------- Intro Section -------------------------
     padding: 10rem 0;
     position: relative;
     display: flex;
     @include resp(screenW1024){
          flex-flow: column-reverse;
     }

     &__card,
     &__R {
          position: relative;
          display: flex;
          flex-flow: column;
          @include resp(screenW1024){
               width: 100%;
          }

          &--title { /*---------------  Intro / Title --------------------*/
               width: calc(( 100% / 4 ) * 3 );
               @include resp(screenW1024){
                    width: 100%;
               }

               &-T {
                    font-size: $xxl;
                    font-weight: 700;
               }

               &-B {
                    padding-top: 2rem;
                    font-size: $lg;
                    font-weight: 600;     
               }

               &-underline {
                    margin: 1rem 0 2rem 0;
               }
          }

          &--body { /*---------------  Intro / Text Body --------------------*/
               width: 95%;
               padding-top: 2rem;
               display: flex;
               flex-flow: column;
               justify-content: flex-start;
               gap: 2rem;

               @include resp(screenW1024){
                    width: 100%;
                    text-align: justify;
               }

               &-par {
                    font-size: $md;
                    line-height: 1.5;
                    text-indent: 5%;
               }
          }

          &--container { /*---------------  Intro / Images Container --------------------*/
               width: 100%;
               height: 100%;
               display: grid;
               grid-template-columns: 1fr;
               grid-gap: 1rem;
               justify-content: center;
               align-items: center;

               @include resp(screenW1024){
                    height: 50rem;
                    margin: 0;
               }

               @include resp(screenW480){
                    height: 80vw;
                    margin: 0;
               }

               &-img1 {
                    background-image: url('../../images/img03.jpg');
               }

               &-img2 {
                    background-image: url('../../images/img01.jpg');
                    @include resp(screenW1024){
                         display: none !important;
                    }
               }

               &-img1,
               &-img2 {
                    display: block;
                    background-color: black;
                    width: 100%;
                    height: 100%;
                    background-position: center center;
                    background-size: cover;
               }
          }
     }

     &__R {
          @include resp(screenW1024){
               padding-bottom: 10rem;
          }
          
     }
}
// CONTRIBUTORS SECTION
.cont {
     width: 100%;
     min-height: 60vh;
     height: auto;
     padding: 5rem 0;
     
     &__col {
          width: 100%;
          height: auto;
          
     }
     
     &__title {
          padding: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;

          &-h2 {
               font-size: $xxxl;
               font-weight: 900;
               color: $color-gray50;

               @include resp(screenW768){
                    font-size: $xxl;
               }

               @include resp(screenW480){
                    font-size: $xl;
               }
          }

          &-h3 {
               font-size: $lg;
               color: $color-gray75;

               @include resp(screenW480){
                    font-size: $md;
                    text-align: center;
               }
          }
     }

     &__logos {
          display: flex;
          align-items: center;
          justify-content: center;

          @include resp(screenW480){
               flex-wrap: wrap;
          }
     }

     &__card {
          width: calc(100% / 4);
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;

          @include resp(screenW480){
               width: calc(100% / 2);
          }

          &-link {
               width: 20rem;
               height: 20rem;
               display: flex;
               align-items: center;

               @include resp(screenW1024){
                    width: 15rem;
                    height: 15rem;
               }

               @include resp(screenW768){
                    width: 10rem;
                    height: 10rem;
               }
          }

          &-text {
               font-size: $md;
               text-align: center;
          }

          &-img {
               max-width: 100%;
               height: auto;
               object-fit: cover;
          }
     }
}

.prjImgs {
     @include fluidContainer;
     flex-flow: column;

     &__cont {
          width: 100%;
          max-height: auto;
     }

     &__cont:not(:last-of-type) {
          padding-bottom: 2rem;
     }

     &__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
     }
}

.lastnews { /*---------------  Last News --------------------*/
     height: auto;
     margin: 10rem 0;
     display: grid;
     grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
     justify-content: center;
     justify-items: center;
     grid-gap: 5rem;

     @include resp(screenW1024){
          grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
     }

     @include resp(screenW768){
          grid-template-columns: 1fr;
          grid-gap: 2rem;
     }
}
     
     