.pdf {
     width: 100%;

     &__info {
          width: 50%;
          margin: 0 auto;
          padding: 2rem 0;
          display: flex;
          justify-content: center;

          @include resp(screenW768) {
               width: 100%;
          }

          @include resp(screenW480) {
               flex-flow: column;
               align-items: center;
          }
     }

     &__pageNumber {
          margin-right: 1rem;
          padding: 1rem;
          text-align: center;
          font-size: $md;
     }

     &__button {
          width: 10rem;
          background-color: $color-link;
          color: $color-white;
          margin: .5rem;
          padding: 1rem;
          cursor: pointer;
     }
}

.react-pdf__Page__canvas {
     margin: 5rem auto 3rem auto;
     border: .1rem solid $color-black;
     border-radius: 1rem;

     @include resp(screenW1024) {
          width: 100% !important;
          height: auto !important;
     }    
}
